import * as React from 'react';
import PageHoc from '../../components/PageHoc/PageHoc';

const AboutPage = () => {
  return (
    <PageHoc>
      <h1>About</h1>
    </PageHoc>
  );
};
export default AboutPage;
