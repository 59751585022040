import * as React from 'react';
import PageHoc from '../../components/PageHoc/PageHoc';

const SandboxPage = () => {
  return (
    <PageHoc>
      <h1>Sandbox</h1>
    </PageHoc>
  );
};
export default SandboxPage;
